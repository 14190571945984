import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { Link } from "gatsby";

const PagenotFound = () => {
  return (
    <Layout>
      <SEO title="Page Not Found" slug="/404" />
      <section className="max-w-7xl mx-auto pt-36 lg:pt-50 px-4">
        <h3 className="text-ni-carbon text-2xl lg:text-3xl lg:pr-36">
          Sorry, the page you are looking for either doesn't exist or has been
          moved.
        </h3>
        <div className="py-12">
                  <Link
                    to="/"
                    className="px-4 py-3 border border-ni-green text-base font-medium  shadow-sm text-ni-green bg-transparent hover:bg-ni-green hover:text-black transition sm:px-8"
                  >
                    Return Home
                  </Link>
                </div>
      </section>
    </Layout>
  );
};

export default PagenotFound;
